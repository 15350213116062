import React from "react";
import { Tabs, Tab, TabList, TabPanels, TabPanel } from "@carbon/react";

import MSPTab from "./TabContent/MSPTab";
import VOIPTab from "./TabContent/VOIPTab";
import SoftwareTab from "./TabContent/SoftwareTab";
import AboutTab from "./TabContent/AboutTab";

// const props = {
//   tabs: {
//     selected: 0,
//     role: 'navigation',
//   },
//   tab: {
//     role: 'presentation',
//     tabIndex: 0,
//   },
// };

const HeroTabs = () => {
  return (
    <Tabs>
      <TabList contained>
        <Tab>Software Services</Tab>
        <Tab>IT Services</Tab>
        <Tab>Unified Communications</Tab>

        <Tab>About Us</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <SoftwareTab />
        </TabPanel>
        <TabPanel>
          <MSPTab />
        </TabPanel>
        <TabPanel>
          <VOIPTab />
        </TabPanel>
        <TabPanel>
          <AboutTab />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default HeroTabs;
