import { Grid, Column } from "@carbon/react";

const VOIPTab = () => {
  return (
    <Grid style={{ marginBottom: '64px'}}>
        <Column
          sm={{ start: 1, end: 8 }}
          md={{ start: 1, end: 15 }}
          lg={{ start: 1, end: 8 }}
          style={{marginTop: '64px'}}
        >
          <h2 className="landing-page__subheading">
            Unified communications with 3CX
          </h2>
          <div className="landing-page__p">
            <p>
              With 3CX, you can enjoy a range of advanced features such as call
              recording, voicemail, and video conferencing, all from an
              easy-to-use web-based interface.
            </p>
            <br />
            <p>
              In addition to 3CX, we offer a range of other VoIP services,
              including SIP trunking, hosted PBX, and unified communications.
              Our team will work closely with you to understand your specific
              communication needs and develop a solution that meets your
              requirements and budget.
            </p>
            <br />
            <p>
              So if you're looking for reliable, affordable VoIP services and
              support for your 3CX installation, look no further than our
              company. Contact us today to learn more about our full range of
              communication services and how we can help you stay connected.
            </p>
          </div>
        </Column>
        <Column
            sm={{ start: 1, end: 8}}
            md={{ start: 1, end: 15 }}
            lg={{ start: 8, end: 15 }}
            style={{marginTop: '64px'}}
        >
          <h2 className="landing-page__subheading">Existing Systems & Other Services</h2>
          <div className="landing-page__p">
            <p>
              At our company, we understand that every business has unique
              communication needs. That's why we offer a range of VoIP
              consulting and support services to help you choose the right
              solution for your business, and to help you optimize your existing
              system.
            </p>
            <br />
            <p>
              If you're not sure which VoIP system is right for your business,
              our team can help. We have years of experience working with a
              variety of VoIP systems and can help you choose the right solution
              based on your specific requirements and budget. We'll take the
              time to understand your business needs and recommend a solution
              that meets your goals, whether it's a hosted PBX, SIP trunking, or
              unified communications.
            </p>
            <br />
            <p>
              If you already have a VoIP system in place, our team can help you
              optimize it for maximum performance and reliability. We can assist
              with installation, configuration, and maintenance of your existing
              system, and can provide ongoing support to ensure that it
              continues to meet your needs as your business grows.
            </p>
          </div>
        </Column>
    </Grid>
  );
};

export default VOIPTab;
