import React, { Component } from 'react';
import './app.scss';
import { Route, Routes } from 'react-router-dom';
import { Content } from '@carbon/react';
import MainHeader from './components/MainHeader';
import LandingPage from './content/LandingPage';
import Footer from './components/Footer'

class App extends Component {
  render() {
    return (
      <>
        <MainHeader />
        <Content>
          <Routes>
            {<Route path="/" element={<LandingPage />} />}
          </Routes>
        </Content>
        <Footer />
      </>
    );
  }
}

export default App;
