import React from "react";
import HeroTabs from "../../components/HeroTabs";
import ContactForm from "../../components/ContactForm";
import { Grid, Column } from "@carbon/react";

const LandingPage = () => {
  return (
    <div className="cds--grid cds--grid--fullwidth landing-page">
      <Grid style={{marginBottom: '48px'}}>
          <h1 className="landing-page__heading" style={{whiteSpace: "nowrap"}}>
            <div>
              Dream
              <h3 className="dot">.</h3>
            </div>
            <div>
              Design
              <h3 className="dot-blue">.</h3>
            </div>
            <div>
              Do
              <h3 className="dot">.</h3>
            </div>
          </h1>
        <Column lg={{ start: 3, end: 16}}>
        </Column>
      </Grid>
      <div className="cds--row landing-page__r2">
        <div className="cds--col cds--no-gutter">
          <HeroTabs />
          <div className="cds--grid cds--grid--no-gutter cds--grid--full-width">
            <div className="landing-page__divider"></div>
          </div>
          <div className="cds--grid cds--grid--no-gutter cds--grid--full-width">
            <h1 className="landing-page__contactheading">Contact Us!</h1>
            <div className="cds--row">
              <div className="cds--col-md-4 cds--col-lg-7">
                <ContactForm />
              </div>
              <div className="cds--col-md-4 cds--offset-lg-1 cds--col-lg-8">
                <img
                  className="landing-page__illo"
                  src={`${process.env.PUBLIC_URL}/Assets/ContactGraphic.png`}
                  alt="Contact Graphic"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
