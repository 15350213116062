import React, {useState} from 'react';
import {
  Header,
  HeaderContainer,
  HeaderName,
  SkipToContent,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderPanel,
  SwitcherItem,
} from '@carbon/react';

import {Switcher,} from '@carbon/react/icons';

import { Link } from 'react-router-dom';

function MainHeader(){
  const [isPanelExpanded, setPanelExpanded] = React.useState(false);

  function switcherClick() {
    if(isPanelExpanded) {
      setPanelExpanded(false)
    } else {
      setPanelExpanded(true)
    }
  }

  return (
    <HeaderContainer
      render={() => (
        <Header aria-label="Selbon Software">
          <SkipToContent />
          <HeaderName element={Link} to="/" prefix="">
            Selbon
            <div className="dot">.</div>
            Software
            <img
              className="logo-box"
              src={`${process.env.PUBLIC_URL}/Assets/Box.png`}
              alt="Selbon Logo Cursor"
              height={24}
            />
          </HeaderName>
          <HeaderGlobalBar>
            <HeaderGlobalAction aria-label="App Switcher" onClick={switcherClick}>
              <Switcher />  
            </HeaderGlobalAction>
          </HeaderGlobalBar>
          <HeaderPanel expanded={isPanelExpanded}>
            <div className='Switcher-module--divider'>
              <span className='Switcher-module--text'>Selbon Companies</span>
            </div>
            <SwitcherItem href="https://tenagamesstudio.com" target="_blank">
              Tena Games Studio
            </SwitcherItem>
            <div className='Switcher-module--divider'>
              <span className='Switcher-module--text'>Customer Portals</span>
            </div>
            <SwitcherItem href="https://phones.selbonsoftware.com" target="_blank">
              Phone Portal
            </SwitcherItem>
          </HeaderPanel>
        </Header>
      )}
    />
  )
};

export default MainHeader;
