import React, { useState } from "react";
import EJS, { init } from "emailjs-com";
import { Form, TextArea, TextInput, Button } from "@carbon/react";

init("user_zoTdw8nsefLzDlFPU8JoY");

function ContactForm() {
  const [disable, setDisable] = React.useState(true);
  const [templateParams, setTemplateParams] = React.useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });
  const handleInput = (event) => {
    let updatedValue;
    switch (event.target.id) {
      case "name":
        updatedValue = { name: event.target.value };
        break;
      case "email":
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (event.target.value.match(mailformat)) {
          updatedValue = { email: event.target.value };
          setDisable(false);
        }
        break;
      case "phone":
        updatedValue = { phone: event.target.value };
        break;
      case "description":
        updatedValue = { description: event.target.value };
        break;
      default:
        console.log("invalid form input");
        break;
    }
    setTemplateParams((templateParams) => ({
      ...templateParams,
      ...updatedValue,
    }));
  };

  const sendEmail = (event) => {
    event.preventDefault();
    EJS.send(
      'SelbonSurveillance_Estim',
      'template_9r1zbdc',
      templateParams
    ).then(
      function(response) {
        console.log('SUCCESS', response.status, response.text);
        event.target.reset();
        setDisable(true);
        alert('Your form has been submitted. We will be in contact with you shortly.')
      },
      function(error) {
        alert('There was an error submitting this form. Please try again later.')
        console.log('FAILED', error);
      }
    );
  };

  return (
    <Form onSubmit={sendEmail}>
      <div style={{ marginBottom: "2rem" }}>
        <TextInput
          id="name"
          invalidText="Invalid error message."
          labelText="Name - Company Name"
          placeholder="Jon Doe - Selbon Software"
          onChange={handleInput}
        />
      </div>
      <div style={{ marginBottom: "2rem" }}>
        <TextInput
          id="email"
          invalidText="Invalid error message."
          labelText="Contact Email"
          placeholder="jondoe@selbonsoftware.com"
          onChange={handleInput}
        />
      </div>
      <div style={{ marginBottom: "2rem" }}>
        <TextInput
          id="phone"
          invalidText="Invalid error message."
          labelText="Phone Number"
          placeholder="1-501-555-5555"
          onChange={handleInput}
        />
      </div>
      <div style={{ marginBottom: "2rem" }}>
        <TextArea
          cols={50}
          helperText="Give us a brief description of your project and any other information that you think would be helpful. (~100 character count maximum)"
          id="description"
          invalidText="Invalid error message."
          labelText="Request"
          placeholder="What would you like help with?"
          onChange={handleInput}
          rows={4}
        />
      </div>
      <Button kind="primary" tabIndex={0} type="submit" disabled={disable}>
        Submit
      </Button>
    </Form>
  );
}

export default ContactForm;
