import { Grid, Column } from "@carbon/react";

const SoftwareTab = () => {
  return (
    <Grid style={{ marginBottom: "64px" }}>
      <Column
        sm={{ start: 1, end: 8 }}
        md={{ start: 1, end: 15 }}
        lg={{ start: 1, end: 8 }}
        style={{ marginTop: "64px"}}
      >
        <h2 className="landing-page__subheading">
          Full Stack Web Application Development
        </h2>
        <div className="landing-page__p">
          <p>
            At our company, we use the latest and most advanced technologies to
            build powerful and scalable web applications. We specialize in using
            React and Supabase to develop high-performing, feature-rich
            applications that meet the specific needs of our clients.
          </p>
          <br />
          <p>
            React is a powerful and popular JavaScript framework that allows us
            to build highly interactive user interfaces quickly and easily. We
            use React to create custom front-end solutions that are responsive,
            fast, and user-friendly.
          </p>
          <br />
          <p>
            Supabase is a modern and scalable backend platform that enables us
            to build cloud-based applications with ease. It provides a wide
            range of features, including database hosting, real-time updates,
            and user authentication, which allows us to develop custom solutions
            tailored to your business needs.
          </p>
          <br />
          <p>
            In addition to React and Supabase, we also specialize in integrating
            SSO (Single Sign-On) with different directory services. This enables
            us to build custom solutions that integrate seamlessly with your
            existing infrastructure, whether you're using Active Directory,
            Google Directory, or any other directory service.
          </p>
          <br />
          <p>
            By using these technologies and integrations, we're able to deliver
            custom web applications that are fast, responsive, and highly
            scalable. Our team of experienced developers work closely with our
            clients to understand their specific needs and requirements, and we
            use these technologies to deliver solutions that exceed their
            expectations.
          </p>
          <br />
          <p>
            If you're looking for a custom web application that is fast, secure,
            and easy to use, contact us today to learn more about how our React
            and Supabase development services, and SSO integrations can help you
            achieve your business goals.
          </p>
        </div>
      </Column>
      <Column
        sm={{ start: 1, end: 8 }}
        md={{ start: 1, end: 15 }}
        lg={{ start: 8, end: 15 }}
        style={{ marginTop: "64px"}}
      >
        <h2 className="landing-page__subheading">
          Custom Management Dashboard Development
        </h2>
        <div className="landing-page__p">
          <p>
            At our company, we understand that businesses need real-time
            visibility into their operations to make informed decisions. That's
            why we offer custom management dashboard development services to
            help you aggregate data from various sources and gain actionable
            insights into your business performance.
          </p>
          <br />
          <p>
            Our team of experienced developers can work with you to design and
            build a custom dashboard that meets your specific requirements.
            Whether you need a dashboard that pulls data from multiple sources,
            such as your CRM, ERP, and social media accounts, or a dashboard
            that provides real-time analytics for your sales team, we can help.
          </p>
          <br />
          <p>
            We use the latest technologies and frameworks to build powerful,
            user-friendly dashboards that provide a comprehensive view of your
            business performance. Our dashboards are designed to be fully
            customizable, allowing you to choose the metrics and KPIs that are
            most important to your business.
          </p>
          <br />
          <p>
            In addition to custom dashboard development, we also offer dashboard
            integration services. If you already have existing data sources or
            analytics tools in place, we can help you integrate them into a
            single, cohesive dashboard that provides a holistic view of your
            business performance.
          </p>
          <br />
          <p>
            At our company, we take a collaborative approach to every project,
            working closely with you to ensure that your custom dashboard meets
            your specific needs and exceeds your expectations. Contact us today
            to learn more about our custom dashboard development services and
            how we can help you gain real-time visibility into your business
            performance.
          </p>
        </div>
      </Column>
    </Grid>
  );
};

export default SoftwareTab;
