import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { HashRouter as Router } from 'react-router-dom';

import 'core-js/es/array/includes'
import 'core-js/es/array/fill'
import 'core-js/es/string/trim'
import 'core-js/es/string/includes'
import 'core-js/es/object/values'


ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
