const MSPTab = () => {
  return (
    <div className="cds--grid cds--grid--no-gutter cds--grid--full-width">
      <div className="cds--row landing-page__tab-content">
        <div className="cds--col-md-4 cds--col-lg-7">
          <h2 className="landing-page__subheading">JumpCloud Partner MSP</h2>
          <div className="landing-page__p">
            <p>
              We're excited to offer our customers the industry-leading
              cloud-based directory service that simplifies identity and access
              management across multiple platforms.{" "}
            </p>
            <br />
            <p>
              JumpCloud is the perfect solution for businesses of all sizes,
              offering a range of features that help streamline IT operations
              and enhance security. With JumpCloud, you can manage user
              identities, devices, and applications from a single, easy-to-use
              console, without the need for on-premises infrastructure.
            </p>
            <br />
            <p>
              As a JumpCloud Partner, we're here to help you get the most out of
              this powerful tool. Our team has the expertise and knowledge to
              help you deploy, configure, and optimize JumpCloud for your
              business needs. Whether you're looking to streamline your IAM
              processes, enhance your security posture, or manage your clients'
              environments more effectively, we're here to support you every
              step of the way.
            </p>
            <br />
            <p>
              Contact us today to learn more about how JumpCloud can benefit
              your business and how we can help you get started.
            </p>
          </div>
        </div>

        <div className="cds--col-md-4 cds--col-lg-7">
          <h2 className="landing-page__subheading">Full Range IT Services</h2>
          <div className="landing-page__p">
            <p>
              In addition to our JumpCloud expertise, we also offer a range of
              other IT services to help businesses of all sizes. Our team has
              years of experience supporting Active Directory, Linux, and general
              PC services, and we're here to help you get the most out of your
              IT environment.
            </p>
            <br />
            <p>
              Whether you need help configuring and managing your Active
              Directory infrastructure, deploying and maintaining your Linux
              servers, or providing general PC services such as hardware
              upgrades and software installations, we have the expertise and
              knowledge to get the job done.
            </p>
            <br />
            <p>
              At our company, we understand that every business has unique IT
              needs, which is why we take a customized approach to every
              project. Our team will work closely with you to understand your
              specific requirements and develop a tailored solution that meets
              your needs and budget.
            </p>
            <br />
            <p>
              So if you're looking for reliable, affordable IT services that you
              can count on, look no further than our company. Contact us today
              to learn more about our full range of IT services and how we can
              help you achieve your business goals.
            </p>
          </div>
        </div>

        {/* <div className="cds--col-md-4 cds--offset-lg-1 cds--col-lg-8">
                    <img
                    className="landing-page__illo"
                    src={`${process.env.PUBLIC_URL}/Assets/SecurityGraphic.png`}
                    alt="Security Camera illustration"
                    />
                </div> */}
      </div>
    </div>
  );
};

export default MSPTab;
