export default function AboutTab() {
  return (
    <div className="cds--grid cds--grid--no-gutter cds--grid--full-width">
      <div className="cds--row landing-page__tab-content">
        <div className="cds--col-md-4 cds--col-lg-7">
          <h2 className="landing-page__subheading">About us!</h2>
          <p className="landing-page__p">
          Welcome to Selbon Software - where we believe that small businesses deserve the same level of technological advantages as large enterprises. Our company was founded with a mission to make these luxuries more accessible and affordable to people who own small businesses, including our own parents, family, and friends who struggle with their IT infrastructure. We know first-hand that as a small business owner, time is of the essence and every minute counts. You shouldn't have to sacrifice your valuable time dealing with sluggish computers or inefficient workflows. That's why we offer comprehensive IT-MSP services to help streamline your business operations. At Selbon Software, we are dedicated to supporting you and your business in any way we can.
          </p>
        </div>
        {/* <div className="cds--col-md-4 cds--offset-lg-1 cds--col-lg-8">
                    <img
                    className="landing-page__illo"
                    src={`${process.env.PUBLIC_URL}/Assets/SecurityGraphic.png`}
                    alt="Security Camera illustration"
                    />
                </div> */}
      </div>
    </div>
  );
};
